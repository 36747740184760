import {
  OPPORTUNITY_MASS_ASSIGN,
  CITY_LIST,
  OPPORTUNITY_SEARCH_LIST,
  HOUSING_TOP_CITY_LIST,
  PT_CITY_LIST,
  RESHARE_OWNER_OPP_LINK,
  PACKAGE_CITY_LIST,
} from "../actions/opportunitySearch.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPPORTUNITY_MASS_ASSIGN:
      return {
        ...state,
        opportunityMassAssignStatus: action.payload,
      };
    case CITY_LIST:
      return {
        ...state,
        citiesMap: action.citiesMap,
        allCities: action.allCities,
        cities: action.cities,
      };
    case OPPORTUNITY_SEARCH_LIST:
      return {
        ...state,
        opportunitySearchList: action.opportunitySearchList,
      };
    case HOUSING_TOP_CITY_LIST:
      return {
        ...state,
        topCities: action.payload,
      };
    case PT_CITY_LIST:
      return {
        ...state,
        ptCitiesIdtoLabel: action.ptCitiesIdtoLabel,
        citiesData: action.citiesData,
        ptCities: action.ptCities,
      };
    case RESHARE_OWNER_OPP_LINK:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Link Re-shared Successfully",
        },
      };
    case PACKAGE_CITY_LIST:
      return {
        ...state,
        packageCities: action.packageCities,
      };
  }
};

export default reducer;
