import { INACTIVE_PROFILE } from "../actions/profile.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case INACTIVE_PROFILE:
      return {
        ...state,
        modalInactiveProfileHousing: action.value,
      };
  }
};

export default reducer;
