import { lazy } from "react";

import { navigations, ROUTES } from "./utils/NavigationUtils";

const AdProductConfig = lazy(() => import("./containers/AdProductConfig"));

const AgentPerformance = lazy(() =>
  import("./containers/Agent/Performance/Performance")
);
const AgentPerformanceReport = lazy(() =>
  import("./containers/agentPerformanceReport/agentPerformanceReport")
);
const AudienceMaximizer = lazy(() => import("./containers/AudienceMaximizer"));

const CampaignModule = lazy(() =>
  import("./containers/CampaignModule/CampaignModule")
);
const ChurnScheduler = lazy(() =>
  import("./containers/ChurnScheduler/ChurnScheduler")
);
const CommonMatrix = lazy(() => import("./containers/CommonMatrix/CommonMatrix"));

const DiscountApprovalMatrix = lazy(() =>
  import("./containers/DiscountMatrix/DiscountMatrix")
);
const ErpEventTracking = lazy(() =>
  import("./containers/ErpEventTracking/ErpEventTracking")
);
const EventsManagement = lazy(() => import("./containers/EventManagement"));
const FlatPanel = lazy(() => import("./containers/FlatPanel"));

const HomeComponent = lazy(() => import("./containers/Home/Home"));
const IncentiveCalculation = lazy(() =>
  import("./containers/IncentiveCalculation/IncentiveCalculation")
);

const IncentivePayout = lazy(() =>
  import("./containers/IncentivePayout/IncentivePayout")
);
const InvoiceReport = lazy(() => import("./containers/InvoiceReport/InvoiceReport"));
const LeadFeedback = lazy(() => import("./containers/LeadFeedback"));

const LiveViewOfAgents = lazy(() =>
  import("./containers/LiveViewOfAgents/LiveViewOfAgents")
);
const LocalityAgentMapping = lazy(() =>
  import("./containers/LocalityAgentMapping/LocalityAgentMapping")
);
const ManagePackages = lazy(() =>
  import("./containers/ManagePackages/ManagePackages")
);
const ManageRegions = lazy(() => import("./containers/ManageRegions/ManageRegions"));

const ManualActivationDashboard = lazy(() =>
  import("./containers/ManualActivationDashboard")
);

const ManualDialing = lazy(() => import("./containers/ManualDialing/ManualDialing"));
const MergeAccounts = lazy(() => import("./containers/MergeAccounts/MergeAccounts"));
const MergeIPDetectedAccounts = lazy(() =>
  import("./containers/MergeIPDetectedAccounts/MergeIPDetectedAccounts")
);
const MigrationOfAgentsCases = lazy(() =>
  import("./containers/MigrationOfAgentsCases/index")
);
const Microsite = lazy(() => import("./containers/Microsite"));

const MonetizableCities = lazy(() =>
  import("./containers/MonetizableCities/MonetizableCities")
);
const MyAccounts = lazy(() => import("./containers/MyAccounts/MyAccounts"));

const MyOpportunities = lazy(() =>
  import("./containers/MyOpportunities/MyOpportunities")
);
const NetSuitData = lazy(() => import("./containers/NetSuitData/NetSuitData"));

const OpportunitySearch = lazy(() =>
  import("./containers/OpportunitySearch/OpportunitySearch")
);
const OwnerCallDetail = lazy(() =>
  import("./containers/OwnerCallDetail/OwnerCallDetail")
);
const PackagePricingManagement = lazy(() =>
  import("./containers/PackagePricingManagement/PackagePricingManagement")
);
const Performance = lazy(() => import("./containers/Performance/Performance"));
const ProductConfig = lazy(() => import("./containers/ProductConfig"));
const ProductPackageFamily = lazy(() =>
  import("./containers/ProductPackageFamily/ProductPackageFamily")
);
const ProductManagement = lazy(() =>
  import("./containers/ProductManagement/ProductManagement")
);
const Reports = lazy(() => import("./containers/Reports/Reports"));
const RmMigration = lazy(() => import("./containers/RmMigration/RmMigration"));
const SalesClosureDate = lazy(() =>
  import("./containers/SalesClosureDate/SalesClosureDate")
);
const SalesReport = lazy(() => import("./containers/SalesReport/SalesReport"));

const SetSalesTarget = lazy(() =>
  import("./containers/SetSalesTarget/SetSalesTarget")
);
const SiteTakeover = lazy(() => import("./containers/SiteTakeover"));

const SlotAvailability = lazy(() =>
  import("./containers/SlotAvailability/SlotAvailability")
);
const SlotCapping = lazy(() => import("./containers/SlotCapping/SlotCapping"));
const Stories = lazy(() => import("./containers/Stories"));

const TargetSettingRules = lazy(() =>
  import("./containers/TargetSettingRules/TargetSettingRules")
);
const TeleSalesDashboard = lazy(() =>
  import("./containers/TeleSalesDashboard/TeleSalesDashboard")
);
const TenantMonetizationDashboard = lazy(() =>
  import("./containers/TenantMonetizationDashboard/TenantMonetizationDashboard")
);
const UserManagement = lazy(() =>
  import("./containers/UserManagement/UserManagement")
);
const VirtualTour = lazy(() => import("./containers/VirtualTour"));

const getComponentByRoute = (route) => {
  switch (route) {
    case ROUTES.HOME:
      return HomeComponent;
    case ROUTES.OWNER_DASHBOARD:
    case ROUTES.BROKER_DASHBOARD:
    case ROUTES.BUILDER_DASHBOARD:
      return TeleSalesDashboard;
    case ROUTES.TENANT_DASHBOARD:
      return TenantMonetizationDashboard;
    case ROUTES.MY_OPPORTUNITIES:
      return MyOpportunities;
    case ROUTES.OPPORTUNITY_SEARCH:
      return OpportunitySearch;
    case ROUTES.CHURN_SCHEDULER:
      return ChurnScheduler;
    case ROUTES.LIVE_VIEW:
      return LiveViewOfAgents;
    case ROUTES.MY_ACCOUNTS:
      return MyAccounts;
    case ROUTES.CAMPAIGN_MODULE:
      return CampaignModule;
    case ROUTES.REPORTS:
      return Reports;
    case ROUTES.MANUAL_DIALING:
      return ManualDialing;
    case ROUTES.RM_MIGRATION:
      return RmMigration;
    case ROUTES.MIGRATION_CASES:
      return MigrationOfAgentsCases;
    case ROUTES.PERFORMANCE:
      return Performance;
    case ROUTES.SLOT_AVAILABILITY:
      return SlotAvailability;
    case ROUTES.AGENT_PERFORMANCE:
      return AgentPerformance;
    case ROUTES.TARGET_SETTING_RULES:
      return TargetSettingRules;
    case ROUTES.SET_SALES_TARGET:
      return SetSalesTarget;
    case ROUTES.INCENTIVE_CALCULATION:
      return IncentiveCalculation;
    case ROUTES.INCENTIVE_PAYOUT:
      return IncentivePayout;
    case ROUTES.PRODUCT_PACKAGE_FAMILY:
      return ProductPackageFamily;
    case ROUTES.PRODUCT_MANAGEMENT:
      return ProductManagement;
    case ROUTES.PACKAGE_PRICING:
      return PackagePricingManagement;
    case ROUTES.MANAGE_PACKAGES:
      return ManagePackages;
    case ROUTES.EVENTS:
      return EventsManagement;
    case ROUTES.PRODUCT_CONFIG:
      return ProductConfig;
    case ROUTES.SLOT_CAPPING:
      return SlotCapping;
    case ROUTES.CALL_DETAIL:
      return OwnerCallDetail;
    case ROUTES.SALES_REPORT:
      return SalesReport;
    case ROUTES.INVOICE_REPORT:
      return InvoiceReport;
    case ROUTES.AGENT_PERFORMANCE_REPORT:
      return AgentPerformanceReport;
    case ROUTES.MANUAL_ACTIVATION_DASHBOARD:
      return ManualActivationDashboard;
    case ROUTES.MICROSITE:
      return Microsite;
    case ROUTES.VIRTUAL_TOUR:
      return VirtualTour;
    case ROUTES.AD_PRODUCT_CONFIG:
      return AdProductConfig;
    case ROUTES.SITE_TAKEOVER:
      return SiteTakeover;
    case ROUTES.FLAT_PANEL:
      return FlatPanel;
    case ROUTES.AUDIENCE_MAXIMIZER:
      return AudienceMaximizer;
    case ROUTES.STORIES:
      return Stories;
    case ROUTES.LEAD_FEEDBACK:
      return LeadFeedback;
    case ROUTES.USER_MANAGEMENT:
      return UserManagement;
    case ROUTES.LOCALITY_AGENT_MAPPING:
      return LocalityAgentMapping;
    case ROUTES.MERGE_ACCOUNTS:
      return MergeAccounts;
    case ROUTES.MERGE_IP_DETECTED_ACCOUNTS:
      return MergeIPDetectedAccounts;
    case ROUTES.MANAGE_REGIONS:
      return ManageRegions;
    case ROUTES.SALES_CLOSURE_DATE:
      return SalesClosureDate;
    case ROUTES.DISCOUNT_MATRIX:
      return DiscountApprovalMatrix;
    case ROUTES.COMMON_MATRICES:
      return CommonMatrix;
    case ROUTES.NETSUITE_PUSH_DATA:
      return NetSuitData;
    case ROUTES.NETSUITE_EVENT_TRACKING:
      return ErpEventTracking;
    case ROUTES.MONETIZABLE_CITIES:
      return MonetizableCities;
    default:
      return null;
  }
};
const navigation = navigations;
const routesMapping = navigation?.map(({ route, roles, nestedMenus }) => {
  const component = getComponentByRoute(route);
  return {
    route,
    roles,
    component,
    nestedMenus: nestedMenus
      ? nestedMenus.map((nestedItem) => ({
          ...nestedItem,
          component: getComponentByRoute(nestedItem.route),
        }))
      : undefined,
  };
});

export { ROUTES, routesMapping };
