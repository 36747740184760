/* eslint-disable prettier/prettier */
import { instance } from '../../lib/api'
import Constants from '../../utils/CommonConstants'
import ownerTrackMap from '../../tracking/ownerGA'
import { gaTrackingFn } from '../../tracking/tracking'

export const CHILD_AGENT_MAP = 'CHILD_AGENT_MAP'
export const AGENT_LIST = 'AGENT_LIST'
export const API_FAIL = 'API_FAIL'
export const ASSIGNMENT_SUCCEED = 'ASSIGNMENT_SUCCEED'
export const ACCOUNT_NAMES = 'ACCOUNT_NAMES'
export const ACCOUNT_LIST = 'ACCOUNT_LIST'
export const TICKETS_ATTRIBUTES = 'TICKETS_ATTRIBUTES'
export const CREATED_TICKET_SUCCESSULLY = 'CREATED_TICKET_SUCCESSULLY'
export const ADDED_ACCOUNT = 'ADDED_ACCOUNT'
export const MASS_ASSIGNMENT_FAILED = 'MASS_ASSIGNMENT_FAILED'
export const HOUSING_ACCOUNT_DETAILS = 'HOUSING_ACCOUNT_DETAILS'
export const ACCOUNT_ADDED = 'ACCOUNT_ADDED'
export const RESET_TICKET_ATTRIBUTES = 'RESET_TICKET_ATTRIBUTES'
export const ACCOUNT_MAPPED_TO_CITY = 'ACCOUNT_MAPPED_TO_CITY'
export const RESET_MAPPED_ACCOUNT = 'RESET_MAPPED_ACCOUNT'
export const USER_DESIGNATION = 'USER_DESIGNATION'
export const ACCOUNTS_LIST_TYPEAHEAD = 'ACCOUNTS_LIST_TYPEAHEAD'
export const RESET_HOUSING_ACCOUNT_NAMES = 'RESET_HOUSING_ACCOUNT_NAMES'
export const TICKETS_SUBCATEGORIES = 'TICKETS_SUBCATEGORIES'
export const EXISTING_ACCOUNT_DETAILS = 'EXISTING_ACCOUNT_DETAILS'
export const UPDATE_DYNAMIC_PROPERTY = 'UPDATE_DYNAMIC_PROPERTY'

const addChild = (agents, child) => {
  const key = `${child.userName} - ${child.userId}`
  agents[key] = {
    id: child.userId,
    name: child.userName
  }
  if (child.child) {
    agents[key].hasChild = true
    child.child.forEach((c) => {
      agents = addChild(agents, c)
    })
  }
  return agents
}

const getChildMap = (childMap, data) => {
  const { userId, child } = data
  childMap[userId] = childMap[userId] || [userId]
  if (child) {
    child.forEach(c => {
      childMap[userId].push(c.userId)
      childMap[userId] = childMap[userId].concat(getChildMap(childMap, c))
    })
  }
  return childMap[userId]
}

const parseAccountDetailsResponse = (account) => {
  const details = {}
  details.status = account.profile_details.status
  details.accountName = account.profile_details.name || account.profile_details.firm_name
  details.pocName = account.profile_details.short_name
  details.email = account.login_details.email || account.profile_details.email_id || account.profile_details.firm_email_id || ''
  details.mobile = account.login_details.phone || account.profile_details.primary_phone_number
  details.pan = account.extra_fields.pan
  details.cityId = account.sales_city_pt_id
  const address = []
  const addressList = Object.values(account.contact_Address)
  addressList.forEach(thisAddress => {
    const addressKeys = ['building_number', 'building_name', 'street', 'locality']
    const fullAddress = []
    if (thisAddress !== null) {
      addressKeys.forEach(key => {
        if (thisAddress[key]) { fullAddress.push(thisAddress[key]) }
      })
      const addressObj = {
        type: 'Other',
        address: fullAddress.join(','),
        pincode: thisAddress.pincode || '',
        cityId: '',
        stateId: '',
        helpdesk: ''
      }
      address.push(addressObj)
    }
  })
  details.address = address
  return details
}

function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const parseExistingAccountDetails = (accountProfiles, existingAccountDetails) => {
  const groupedAccounts = groupBy(existingAccountDetails, account => account.housingProfileId)
  const accountNames = []
  accountProfiles.forEach(account => {
    const id = account.id
    const accountNameDetails = account.name.split('-')
    let name = `${accountNameDetails[0]}`
    if (accountNameDetails[1]) { name += ` | Email - ${accountNameDetails[1]}` }
    if (accountNameDetails.length > 2) {
      name += ` | Contacts - ${accountNameDetails.slice(2).join()}`
    }
    if (groupedAccounts.get(id)) {
      const accountList = groupedAccounts.get(id)
      accountList.forEach(existingDetail => {
        let nameWithCityAndOwner = name
        if (existingDetail.cityName) {
          nameWithCityAndOwner += ` | City - ${existingDetail.cityName}`
        }
        if (existingDetail.ownerName) {
          nameWithCityAndOwner += ` | Assigned To - ${existingDetail.ownerName}`
        }

        accountNames.push({
          name: nameWithCityAndOwner,
          housingName: existingDetail.name,
          pan: existingDetail.pan,
          gstin: existingDetail.gstin,
          housingProfileId: id,
          addressDetails: existingDetail.address || [],
          id,
          alreadyAssigned: true,
          cityId: existingDetail.cityId,
          salesAccountId: existingDetail.id,
          ownerName: existingDetail.ownerName,
          cityName: existingDetail.cityName
        })
      })
    } else {
      accountNames.push({
        name,
        id,
        alreadyAssigned: false
      })
    }
  })
  return accountNames
}

export const getAgents = ({ companyId, checkMasterRole } = {}) => {

  let url = '/madrox/app/v1/entity/user/child';
  const queryParams = [];

  if (companyId) {
    queryParams.push(`companyId=${companyId}`);
  }

  if (checkMasterRole) {
    const userIdQuery = `userId=${Constants.dealDeskUserId}`;
    queryParams.push(userIdQuery);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const data = response.data.data;

        const agents = addChild({}, data)
        dispatch({
          type: AGENT_LIST,
          agents
        })

        const childMap = {}
        getChildMap(childMap, data)

        dispatch({
          type: CHILD_AGENT_MAP,
          payload: childMap
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}
const addEqualFilter = (filterSelector, type, value) => {
  const equal = {}
  equal[type] = value
  filterSelector.filters.and.push({ equal: equal })
}
const addDateRangeFilter = (fiqlFilter, key, dateRange) => {
  const dates = {}
  dates.from = dateRange.start ? dateRange.start.valueOf() : Constants.minDateAllowed.valueOf()
  dates.to = dateRange.end ? dateRange.end.valueOf() : (new Date()).getTime()
  const range = {}
  range[key] = dates
  fiqlFilter.filters.and.push({ range: range })
}

export const getAccountList = (team, agentIds, accountId, accountName, createdAt, updatedAt, page, afterMassUpdate, typeAhead, cityIds, clientStatusId, sellerIds, accountPocContactNumbers, accountUUID) => {
  const rows = 100
  const paging = afterMassUpdate ? { start: 0, rows: page * rows } : { start: (page - 1) * rows, rows }
  const cachingFilter = {}
  const sort = [
    {
      field: 'accountId',
      sortOrder: 'DESC'
    }
  ]
  const filterSelector = {
    team,
    sort,
    paging,
    key: 'BASED_ON_OUTER_FILTER_WITH_COMPANY_ID_AGENT_FILTER',
    filters: {
      and: []
    }
  }
  if (accountId.length > 0) {
    addEqualFilter(filterSelector, 'accountId', accountId)
    cachingFilter.accountId = parseInt(accountId[0])
  }
  if (agentIds.length !== 0) {
    addEqualFilter(filterSelector, 'agentId', agentIds)
    cachingFilter.agentIds = agentIds
  }
  if (cityIds) {
    addEqualFilter(filterSelector, 'cityIds', [cityIds])
    cachingFilter.cityIds = cityIds
  }
  if (createdAt.start || createdAt.end) {
    addDateRangeFilter(filterSelector, 'createdAt', createdAt)
    cachingFilter.createdAt = createdAt
  }
  if (updatedAt.start || updatedAt.end) {
    addDateRangeFilter(filterSelector, 'updatedAt', updatedAt)
    cachingFilter.updatedAt = updatedAt
  }
  if (clientStatusId) {
    addEqualFilter(filterSelector, 'clientStatusId', clientStatusId)
    cachingFilter.clientStatusId = clientStatusId
  }
  if (sellerIds.length > 0) {
    addEqualFilter(filterSelector, 'makaanSellerIds', sellerIds)
    cachingFilter.sellerIds = sellerIds
  }
  if (accountPocContactNumbers) {
    addEqualFilter(filterSelector, 'accountPocContactNumbers', accountPocContactNumbers)
    cachingFilter.accountPocContactNumbers = accountPocContactNumbers
  }
  if (accountUUID) {
    addEqualFilter(filterSelector, 'housingProfileId', accountUUID)
  }
  if (accountName && accountName.length > 0) {
    filterSelector.text = encodeURIComponent(accountName)
    cachingFilter.accountName = accountName
  }

  const url = `/mystique/v1/sac/key-search?salesAccountSelectorsArray=[${JSON.stringify(filterSelector)}]`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        let accountList = {
          totalCount: 0,
          results: []
        }
        if (response.data.data[0]) { accountList = response.data.data[0] }
        accountList.isAfterMassUpdate = afterMassUpdate

        if (typeAhead) {
          const accountsListTypeAhead = {}
          accountList.results.forEach(account => {
            accountsListTypeAhead[account.accountName + ' - ' + account.agentName] = account
          })
          dispatch({
            type: ACCOUNTS_LIST_TYPEAHEAD,
            accountsListTypeAhead
          })
        } else {
          dispatch({
            type: ACCOUNT_LIST,
            accountList
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const massUpdate = ({
  ids, assigneeAgentId, refresh, page, payload = {},
}) => {
  const url = '/sapna/v1/account/mass-update-assignment'
  // const url = '/mystique/v1/sac/mass-update-assignment';
  return (dispatch, getState) => {
    return instance({
      method: 'PUT',
      url,
      data: {
        accountIds: ids,
        agentId: assigneeAgentId,
        ...payload
      }
    })
      .then((response) => {
        var assignmentSucceed = false
        if (response.status == '200') {
          assignmentSucceed = true
          const { userInfo: { roles } } = getState();
          if (roles.includes(Constants.housingOwnerAgentRole)) {
            dispatch(gaTrackingFn(ownerTrackMap.REASSIGNED_ACCOUNTS, {
              accountIds: ids,
              agentId: assigneeAgentId
            }))
          }
        }
        refresh(true, false, page)
        dispatch({
          type: ASSIGNMENT_SUCCEED,
          assignmentSucceed
        })
      })
      .catch((error) => {
        dispatch({
          type: MASS_ASSIGNMENT_FAILED,
          errMsg: Constants.getErrorMessage(error)
        })
      })
  }
}

export const createNewTicket = (newTicketDetails, summary, description, userId, category, subcategory) => {
  const url = '/makaanTickets/api/v1/ticket/create'
  const mappingAttributes = process.env.NODE_ENV === 'production' ? Constants.accountMapTicketAttributes : Constants.accountMapTicketAttributesBETA
  const newAttributes = Constants.newAccountTicketAttributes
  if (description == '') {
    description = `<p>Account Ticket for Account - ${newTicketDetails[mappingAttributes.name] || newTicketDetails[newAttributes.name]}</p>`
  }
  return (dispatch) => {
    return instance({
      method: 'POST',
      url,
      data: {
        summary: summary || 'Account Ticket',
        category: {
          id: category
        },
        sub_category: {
          id: subcategory || category
        },
        type: {
          id: 1
        },
        priority: {
          id: 1
        },
        reporter: {
          id: userId
        },
        status: {
          id: 1
        },
        description: description,
        ticket_attributes: Object.keys(newTicketDetails).map(field => (
          { attribute_id: field, attribute_value: newTicketDetails[field] || '' }))
      }
    })
      .then((response) => {
        dispatch({
          type: CREATED_TICKET_SUCCESSULLY,
          ticketLink: { key: response.data.data.key, url: response.data.data.site_link }
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getTicketsAttributes = (categoryId) => {
  const url = `/makaanTickets/api/v1/ticket/master/category/${categoryId}/attributes`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const ticketsAttributes = response.data.data
        dispatch({
          type: TICKETS_ATTRIBUTES,
          ticketsAttributes
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getAccountNames = (name, profileType) => {
  const url = `/sapna/v1/housing/helper/1?profile_type=${profileType}&q=${name}${profileType === Constants.profileTypeOwner ? '&is_owner=true' : ''}`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        dispatch({
          type: ACCOUNT_NAMES,
          accountNames: response.data.data
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getAccountDetailsFromHousing = (accountUUID) => {
  const url = `/sapna/v1/housing/helper/2?profile_uuids=${accountUUID}`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const housingAccountDetails = parseAccountDetailsResponse(response.data.data.results[accountUUID])
        housingAccountDetails.housingProfileId = accountUUID
        dispatch({
          type: HOUSING_ACCOUNT_DETAILS,
          housingAccountDetails: housingAccountDetails
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const resetHousingAccountNames = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_HOUSING_ACCOUNT_NAMES
    })
  }
}

export const addAccount = (data, refresh, currentPage) => {
  const url = '/sapna/v1/account'
  return (dispatch) => {
    return instance({
      url,
      method: 'post',
      data
    })
      .then(() => {
        refresh(true, false, currentPage)
        dispatch({
          type: ACCOUNT_ADDED
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const resetAttributes = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_TICKET_ATTRIBUTES
    })
  }
}

export const mapAccountToCity = (accountId, cityId, newAddress) => {
  const url = `/sapna/v1/account/city?accountId=${accountId}&cityId=${cityId}`
  return (dispatch) => {
    const address = newAddress && newAddress.trim()
    return instance({
      url,
      method: 'post',
      data: address ? [{
        address: address || '',
        cityId: address ? cityId : null,
        helpdesk: '',
        pincode: '',
        stateId: '',
        type: 'Other'
      }] : []
    })
      .then((response) => {
        const { data } = response.data
        if (data && data.id) {
          dispatch({
            type: ACCOUNT_MAPPED_TO_CITY,
            payload: data
          })
        } else {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: 'Unable to map account'
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const resetMappedAccount = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_MAPPED_ACCOUNT
    })
  }
}

export const getDesignation = () => {
  return (dispatch) => {
    const url = '/madrox/data/v2/entity/user/company-user?fields=designation,label'
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const designation = (response.data.data.designation && response.data.data.designation.label) || ''
        dispatch({
          type: USER_DESIGNATION,
          designation
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getTicketsSubcategories = (categoryId) => {
  const url = `/makaanTickets/api/v1/ticket/master/category/${categoryId}/subcategory`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const ticketSubcategories = {}
        response.data.data.forEach(subcategory => {
          ticketSubcategories[subcategory.name] = subcategory.id
        })
        dispatch({
          type: TICKETS_SUBCATEGORIES,
          ticketSubcategories
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getExistingAccountsDetailsUsingProfileIds = (accountProfiles) => {
  const url = `/sapna/v1/account?filters=${accountProfiles.map(profile => `housingProfileId==${profile.id}`).join(',')}`
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
      .then((response) => {
        const existingAccountDetails = parseExistingAccountDetails(accountProfiles, response.data.data)
        dispatch({
          type: EXISTING_ACCOUNT_DETAILS,
          existingAccountDetails
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err)
        })
      })
  }
}

export const getDynamicPropertyByKey = (key) => {
  const url = `/sapna/dynamicProperties/get-by-key?key=${key}`;
  return (dispatch) => instance({
    url,
  })
    .then((response) => {
      const { data: { data: { propValue, propKey } = {} } = {} } = response
      dispatch({
        type: UPDATE_DYNAMIC_PROPERTY,
        payload: {
          propValue,
          propKey,
        }
      })
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err)
      })
    })
}

export const updateDynamicPropertyByKey = ({
  key,
  value,
  email
}) => (dispatch) => {
  const url = `/sapna/dynamicProperties/update-by-key?key=${key}&value=${value}&emailId=${email}`;
  return instance({
    url,
    method: 'put'
  })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err)
      })
    })
}