import {
  CHILD_USER_LIST,
  USER_UPDATE_SUCCESS,
  AGENT_CITY_UPDATED,
  USER_UPDATE_FAIL,
  MASTER_DESIGNATION,
  MAKAAN_CITY_LIST_FORMATTED,
  AGENT_BRANCH_CITY_MAPPING,
  GET_AGENT_PHONE,
  AGENT_CONTACT_UPDATED,
  MADROX_GET_CONTACT,
  CAMPAIGN_ADDED,
  ALL_CAMPAIGNS,
  GET_AGENT_CAMPAIGN,
  SET_VIRTUAL_NUMBER_INFO,
  VIRTUAL_NUMBER_RESSIGNED,
  USER_MANAGEMENT_DOWNLOAD_CSV,
  HRIS_DETAILS_IN_PROGRESS,
  HRIS_DETAILS_ERROR,
  HRIS_DETAILS_SUCCESS,
  CHILD_USER_LIST_RESET,
} from "../actions/userManagement.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHILD_USER_LIST:
      return {
        ...state,
        childUserList: action.childUserList,
        childUserListTotalCount: action.childUserListTotalCount,
        managerList: action.managerList,
        allChildListMap: action.allChildListMap,
        userUpdationSuccess: "",
        userIdNameChildMapping: action.userIdNameChildMapping,
        userIdChildMap: action.userIdChildMap,
        userEmpCodeMap: action.userEmpCodeMap,
      };
    case CHILD_USER_LIST_RESET:
      return {
        ...state,
        childUserList: action.childUserList,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        childUserList: null,
        userUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "User updated successfully",
        },
      };
    case AGENT_CITY_UPDATED:
      return {
        ...state,
        userUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Agent City updated successfully",
        },
      };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        userUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case MASTER_DESIGNATION:
      return {
        ...state,
        masterDesignations: action.masterDesignations,
        masterDesignationsMap: action.masterDesignationsMap,
      };
    case MAKAAN_CITY_LIST_FORMATTED:
      return {
        ...state,
        makaanCities: action.makaanCities,
        makaanCitiesMap: action.makaanCitiesMap,
      };
    case AGENT_BRANCH_CITY_MAPPING:
      return {
        ...state,
        agentBranchCityMap: action.agentBranchCityMap,
      };
    case GET_AGENT_PHONE:
      return {
        ...state,
        agentIdPhoneMap: action.agentIdPhoneMap,
      };
    case AGENT_CONTACT_UPDATED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Agent Contact updated successfully",
        },
      };
    case MADROX_GET_CONTACT:
      return {
        ...state,
        madroxContactNumberUserDetails: action.madroxContactNumberUserDetails,
      };
    case ALL_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case CAMPAIGN_ADDED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: action.payload,
          type: "success",
        },
      };
    case GET_AGENT_CAMPAIGN:
      return {
        ...state,
        agentCampaigns: action.payload,
      };
    case SET_VIRTUAL_NUMBER_INFO:
      return {
        ...state,
        virtualNumberDialogInfo: action.payload,
      };
    case VIRTUAL_NUMBER_RESSIGNED:
      return {
        ...state,
        virtualNumberDialogInfo: {
          ...state.virtualNumberDialogInfo,
          virtualNumberChanged: true,
        },
      };
    case USER_MANAGEMENT_DOWNLOAD_CSV:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "info",
          message: "Download has started. Please wait...",
        },
      };
    case HRIS_DETAILS_SUCCESS:
      return {
        ...state,
        hrisDetailsMap: {
          ...state.hrisDetailsMap,
          [action.employeeCode]: {
            success: action.payload,
            inProgress: false,
            error: null,
          },
        },
      };
    case HRIS_DETAILS_ERROR:
      return {
        ...state,
        hrisDetailsMap: {
          ...state.hrisDetailsMap,
          [action.employeeCode]: {
            success: null,
            inProgress: false,
            error: action.payload,
          },
        },
      };
    case HRIS_DETAILS_IN_PROGRESS:
      return {
        ...state,
        hrisDetailsMap: {
          ...state.hrisDetailsMap,
          [action.employeeCode]: {
            success: null,
            inProgress: action.payload,
            error: null,
          },
        },
      };
  }
};

export default reducer;
