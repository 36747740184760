import {
  OPPORTUNITY_DETAILS,
  OPPORTUNITY_CREATED,
  OPPORTUNITY_CREATION_FAILED,
  RESET_OPPORTUNITY_CREATION,
  TICKETS_LIST,
  MILESTONE_CREATED,
  RESET_MILESTONE,
  MILESTONE_UPDATED,
  OPPORTUNITY_UPDATED,
  OPPORTUNITY_UPDATE_FAILED,
  MILESTONE_DELETED,
  PROJECT_ATTRIBUTES,
  ALL_PROJECTS,
  PROJECTS_LINKED_WITH_OPPORTUNITY,
  PROJECT_LINKING_FAILED,
  OPPORTUNITY_PACKAGE_OR_PROJECT_MAPPING_DELETED,
  SENT_FOR_DISCOUNT_APPROVAL,
  SENT_FOR_PRODUCT_APPROVAL,
  SENT_FOR_DISCOUNT_APPROVAL_FAILED,
  SENT_FOR_PRODUCT_APPROVAL_FAILED,
  SENT_FOR_EXTENSION_APPROVAL,
  SENT_FOR_EXTENSION_APPROVAL_FAILED,
  DISCOUNT_APPROVED,
  DISCOUNT_REJECTED,
  PRODUCT_APPROVED,
  PRODUCT_REJECTED,
  DISCOUNT_APPROVAL_HISTORY,
  RESET_DISCOUNT_APPROVAL_HISTORY,
  REVIEWRS_LIST,
  PAYMENTS_REVIEWRS_LIST,
  REVIEW_APPROVE_APPROVAL_FLOW,
  SENT_FOR_APPROVAL_FLOW,
  ALL_APPROVALS_NEEDED,
  GENERATE_TAX_INVOICE,
  HOUSING_PROFILE_DATA,
  ASSIGN_OPPORTUNITY,
  ALL_PROJECTS_OF_BUILDER,
  SENT_FOR_PRICE_RESTORATION,
  ALL_PROJECTS_IN_REGION,
  SET_OPPORTUNITY_TAGS_IN_ACCOUNT,
  SET_OPPORTUNITY_TAG,
  SET_USER_STATUS_OF_OPPORTUNITY,
  DONT_INITIATE_NEXT_CALL,
  INITIATE_NEXT_CALL,
  MARK_OPPORTUNITY_LOST,
  SET_MORTGAGE_DATA,
  SET_MORTGAGE_PAYOUT_RANGE,
  MORTGAGE_POSSIBLE_REMARKS,
  MORTGAGE_PARTNER_ACTION_FAILED,
  MORTGAGE_PARTNER_ACTION_SUCCESS,
  MORTGAGE_SUBMITTED_REMARKS,
  SET_MORTGAGE_DISBURSAL_API_FAIL,
  OWNER_PAID_DISCOUNT_DATA,
} from "../actions/opportunity.actions";

import CommonConstants from "../../utils/CommonConstants";

const addOpportunityTag = (opportunity, opportunityTags) => {
  if (opportunity) {
    const { opportunityId, data: opportunityData } = opportunity;
    const opportunityTagsMap = {};
    opportunityTags.forEach(
      ({ opportunityId, renewalTag, renewalAlertTag }) =>
        (opportunityTagsMap[opportunityId] = { renewalTag, renewalAlertTag })
    );
    const { renewalTag = "", renewalAlertTag = "" } =
      opportunityTagsMap[opportunityId] || {};

    return {
      opportunityId,
      data: {
        ...opportunityData,
        // renewalAlertTag will take precendence over renewalTag
        renewalTag: renewalAlertTag || renewalTag,
      },
    };
  }
};

const addOpportunityTagsToAccount = (account, opportunityTags) => {
  const { accountId, data: accountData } = account;
  const opportunityTagsMap = {};
  const { opportunities = [], ...otherData } = accountData || {};
  const {
    upForRenewal: [expireIn7, expireIn8to15],
  } = CommonConstants.getDefaultRenewalReportFilter();
  opportunityTags.forEach(
    ({ opportunityId, renewalTag, renewalAlertTag }) =>
      (opportunityTagsMap[opportunityId] = { renewalTag, renewalAlertTag })
  );
  const updatedOpportunities = opportunities.map((opportunity) => {
    const { id } = opportunity;
    const { renewalTag = "", renewalAlertTag = "" } = opportunityTagsMap[id] || {};

    return {
      ...opportunity,
      // renewalAlertTag will take precendence over renewalTag
      renewalTag: renewalAlertTag || renewalTag,
      // opportunity expiring in 15 days
      rowClassName:
        renewalAlertTag === expireIn7 || renewalAlertTag === expireIn8to15
          ? "row__highlight"
          : "",
    };
  });
  return { accountId, data: { ...otherData, opportunities: updatedOpportunities } };
};

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPPORTUNITY_DETAILS:
      return {
        ...state,
        opportunity: action.payload,
        discountApproval: null,
        productApproval: null,
        projectLinked: null,
      };
    case OPPORTUNITY_UPDATED:
      return {
        ...state,
        newOpportunity: action.payload,
        // opportunity: action.payload, //  comment this is bad, but is required as PUT api don't return account details
        snackbar: {
          state: true,
          message: "Opportunity Updated",
          type: "success",
        },
      };
    case OPPORTUNITY_CREATED:
      return {
        ...state,
        newOpportunity: action.payload,
        // opportunity: action.payload, //  comment this is bad, but is required as PUT api don't return account details
        snackbar: {
          state: true,
          message: "New opportunity created!. You can add products to it.",
          type: "success",
        },
      };
    case OPPORTUNITY_UPDATE_FAILED:
      return {
        ...state,
        newOpportunity: {
          creationFailed: true,
        },
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Unable to update opportunity",
        },
      };
    case OPPORTUNITY_CREATION_FAILED:
      return {
        ...state,
        newOpportunity: {
          creationFailed: true,
        },
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Opportunity creation failed!",
        },
      };
    case RESET_OPPORTUNITY_CREATION:
      return {
        ...state,
        newOpportunity: null,
      };
    case TICKETS_LIST:
      return {
        ...state,
        ticketsList: action.ticketsList,
      };
    case MILESTONE_CREATED:
      return {
        ...state,
        newMilestone: action.payload,
        snackbar: {
          state: true,
          message: "Milestone created",
          type: "success",
        },
      };
    case RESET_MILESTONE:
      return {
        ...state,
        newMilestone: null,
        deletedMilestone: null,
      };
    case MILESTONE_UPDATED:
      return {
        ...state,
        newMilestone: action.payload,
        snackbar: {
          state: true,
          message: "Milestone updated",
          type: "success",
        },
      };
    case MILESTONE_DELETED:
      return {
        ...state,
        deletedMilestone: action.payload || {},
        snackbar: {
          state: true,
          message: "Milestone deleted",
          type: "success",
        },
      };
    case PROJECT_ATTRIBUTES:
      return {
        ...state,
        projectTicketAttributes: action.projectTicketAttributes,
      };
    case ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.allProjects,
      };
    case PROJECTS_LINKED_WITH_OPPORTUNITY:
      return {
        ...state,
        projectLinked: true,
        snackbar: {
          state: true,
          message: "Project Linking Successful!",
          type: "success",
        },
      };
    case MARK_OPPORTUNITY_LOST:
      return {
        ...state,
        opportunityMarkedAsLost: [...action.payload],
      };
    case PROJECT_LINKING_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Failed to link projects",
          type: "error",
        },
      };
    case OPPORTUNITY_PACKAGE_OR_PROJECT_MAPPING_DELETED:
      return {
        ...state,
        projectLinked: true,
      };
    case SENT_FOR_DISCOUNT_APPROVAL:
      return {
        ...state,
        discountApproval: "requested",
        snackbar: {
          state: true,
          message: "Opportunity sent for discount approval",
          type: "success",
        },
      };
    case SENT_FOR_PRODUCT_APPROVAL:
      return {
        ...state,
        productApproval: "requested",
        snackbar: {
          state: true,
          message: "Opportunity sent for product approval",
          type: "success",
        },
      };
    case SENT_FOR_DISCOUNT_APPROVAL_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg,
        },
      };
    case SENT_FOR_PRODUCT_APPROVAL_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg,
        },
      };
    case SENT_FOR_EXTENSION_APPROVAL:
      return {
        ...state,
        extensionApproval: "requested",
        snackbar: {
          state: true,
          message: "Opportunity sent for Extension approval",
          type: "success",
        },
      };
    case SENT_FOR_EXTENSION_APPROVAL_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg,
        },
      };
    case DISCOUNT_APPROVED:
      return {
        ...state,
        discountApproval: "processed",
        snackbar: {
          state: true,
          message: `Discount request ${
            action.isReviewer ? "reviewed" : "approved"
          }!`,
          type: "success",
        },
      };
    case DISCOUNT_REJECTED:
      return {
        ...state,
        discountApproval: "processed",
        snackbar: {
          state: true,
          message: "Discount request rejected!",
          type: "error",
        },
      };
    case PRODUCT_APPROVED:
      return {
        ...state,
        productApproval: "processed",
        snackbar: {
          state: true,
          message: "Product request approved!",
          type: "success",
        },
      };
    case PRODUCT_REJECTED:
      return {
        ...state,
        productApproval: "processed",
        snackbar: {
          state: true,
          message: "Product request rejected!",
          type: "error",
        },
      };
    case DISCOUNT_APPROVAL_HISTORY:
      return {
        ...state,
        approvalHistory: action.approvalHistory,
      };
    case RESET_DISCOUNT_APPROVAL_HISTORY:
      return {
        ...state,
        approvalHistory: null,
      };
    case REVIEWRS_LIST:
      return {
        ...state,
        reviewerList: action.reviewerList,
      };
    case PAYMENTS_REVIEWRS_LIST:
      return {
        ...state,
        paymentsReviewerList: action.paymentsReviewerList,
      };
    case REVIEW_APPROVE_APPROVAL_FLOW:
      return {
        ...state,
        reviewApproveApproval: action.payload,
        discountApproval: REVIEW_APPROVE_APPROVAL_FLOW,
      };
    case SENT_FOR_APPROVAL_FLOW:
      return {
        ...state,
        sentForApproval: action.payload,
        discountApproval: SENT_FOR_APPROVAL_FLOW,
      };
    case ALL_APPROVALS_NEEDED:
      return {
        ...state,
        allApprovalsNeeded: action.payload,
      };
    case GENERATE_TAX_INVOICE:
      return {
        ...state,
        generatedTaxInvoiceStatus: action.payload,
      };
    case HOUSING_PROFILE_DATA:
      return {
        ...state,
        housingProfileDetails: action.payload,
      };
    case ASSIGN_OPPORTUNITY:
      return {
        ...state,
        opportunityAssignmentData: action.payload,
      };
    case ALL_PROJECTS_OF_BUILDER:
      return {
        ...state,
        projectsOfBuilder: action.payload,
      };
    case SENT_FOR_PRICE_RESTORATION:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Approval sent for Price Restoration",
          type: "success",
        },
      };
    case ALL_PROJECTS_IN_REGION:
      return {
        ...state,
        allProjectsInRegion: action.value,
      };
    case SET_OPPORTUNITY_TAGS_IN_ACCOUNT:
      return {
        ...state,
        account: state.enableRenewalDashboard
          ? addOpportunityTagsToAccount(state.account, action.payload)
          : state.account,
      };
    case SET_OPPORTUNITY_TAG:
      return {
        ...state,
        opportunity: addOpportunityTag(state.opportunity, action.payload),
      };
    case SET_USER_STATUS_OF_OPPORTUNITY:
      return {
        ...state,
        opportunityAccountStatus: action.payload,
      };
    case INITIATE_NEXT_CALL:
      return {
        ...state,
        initiateNextCall: true,
      };
    case DONT_INITIATE_NEXT_CALL:
      return {
        ...state,
        initiateNextCall: false,
      };
    case SET_MORTGAGE_DATA:
      return {
        ...state,
        createMortgagePartner: false,
        mortgageData: action.payload,
      };
    case SET_MORTGAGE_DISBURSAL_API_FAIL:
      return {
        ...state,
        createMortgagePartner: false,
      };
    case SET_MORTGAGE_PAYOUT_RANGE:
      return {
        ...state,
        mortgagePayoutRange: action.payload,
      };
    case MORTGAGE_POSSIBLE_REMARKS:
      return {
        ...state,
        mortgageRemarks: action.payload,
      };
    case MORTGAGE_SUBMITTED_REMARKS:
      return {
        ...state,
        mortgageSubmittedRemarks: action.payload,
      };
    case MORTGAGE_PARTNER_ACTION_FAILED:
      return {
        ...state,
        createMortgagePartner: true,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Please try Again",
        },
      };
    case MORTGAGE_PARTNER_ACTION_SUCCESS:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          message: "Success. Action performed successfully!",
        },
      };
    case OWNER_PAID_DISCOUNT_DATA: {
      return {
        ...state,
        ownerPaidDiscountData: action.payload,
      };
    }
  }
};

export default reducer;
