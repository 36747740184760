import Constants from "../../utils/CommonConstants";
import { updateDuration } from "../../utils/dateUtils";
//main list page
export const siteTakeoverSlotsParser = (siteSlots) => {
  siteSlots = siteSlots.map((slot) => {
    const {
      entity_id: entityId,
      promoted_entity_type: promotedEntity,
      slot_id: slotId,
      start_date: startDate,
      end_date: endDate,
      slot_status: status,
      seller_uuid: sellerId,
      slot_status: slotStatus,
      outer_churn_radius: outerChurnRadius,
      inner_churn_radius: innerChurnRadius,
      service,
      opportunity_id: opportunityId,

      project_ids = [],
      polygon_name,
      product_type_id,
      builder_name,
    } = slot;
    return {
      entityId,
      promotedEntity,
      slotId,
      startDate,
      endDate,
      status,
      sellerId,
      polygonIds: polygon_name,
      slotStatus,
      outerChurnRadius,
      innerChurnRadius,
      service,
      opportunityId,
      product_type: Object.values(Constants.productTypes).reduce((res, item) => {
        const test = { ...res };
        test[item.value] = item.label;
        return test;
      }, {})[product_type_id],
      seller_uuid: builder_name,
      builder_project_ids: project_ids.join(","),
    };
  });
  return siteSlots;
};

export const getCitiesByPolygons = (polygonIds, cityData) => {
  let cityList = [];
  if (polygonIds.length && cityData) {
    for (let polygon of polygonIds) {
      let city = cityData.other_cities.find((val) => val.polygon_uuid === polygon);
      if (city) {
        cityList.push(city.name);
      } else {
        let top_city = cityData.top_cities.find(
          (val) => val.polygon_uuid === polygon
        );
        if (top_city) {
          cityList.push(top_city.name);
        }
      }
    }
  }
  return cityList;
};

const getImageCardDataForEdit = (id, imageObj) => {
  let imageArr = [];
  for (let key in imageObj) {
    if (imageObj[key] && imageObj[key].card_id) {
      imageArr.push({
        content: imageObj[key].content,
        priority: imageObj[key].priority,
        product_id: "74",
        slot_id: id,
        id: imageObj[key].card_id,
        status: "active",
        sub_title: imageObj[key].sub_title,
        title: imageObj[key].title,
      });
    }
  }
  return imageArr;
};

export const validateSiteTakeoverFormData = (data, questions) => {
  let errors = [];
  questions.forEach(({ propKey, validate, fields }) => {
    let errMsg = "";
    let { products: { 74: val } = {} } = data;
    if (!validate) return;
    errMsg = validate(val, fields, propKey);
    !!errMsg && errors.push(errMsg);
  });
  return errors;
};

export const validateCreateSlotData = (data, questions) => {
  let errors = [];
  const productId = "74";
  const { products } = data || {};
  const slotData = products[productId] || {};
  const { product_details = {} } = slotData;

  for (let question of questions[0].fields) {
    if (question.required) {
      if (
        question.propKey === "start_date" &&
        slotData["start_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (
        question.propKey === "end_date" &&
        slotData["end_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (
        (question.propKey === "site_takeover_title" ||
          question.propKey === "site_takeover_subtitle") &&
        product_details[question.propKey] === undefined
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (
        slotData[question.propKey] === undefined &&
        question.propKey !== "promotion_type" &&
        question.propKey !== "site_takeover_title" &&
        question.propKey !== "site_takeover_subtitle"
      ) {
        errors.push(`Please enter ${question.title}`);
      }
    }
  }
  if (
    new Date(slotData["start_date"]).getTime() >
    new Date(slotData["end_date"]).getTime()
  ) {
    errors.push("Start Date should be earlier than or equal to End Date");
  }
  return errors;
};
//create and update
export const createSlotApiData = (data, update) => {
  const {
    slotDetails: {
      id,
      promotion_type,
      entity_type_id,
      product_type,
      polygon_uuids,
      amount,
      supply_user_uuid,
      entity_id,
      builder_project_ids,
      opportunity_id,
      product_activation_id,
      start_date,
      end_date,
      organic_hard_target,
      organic_soft_target,
      inner_churn_radius,
      outer_churn_radius,
      profileType,
      notes,
      offer_text,
      property_video_url,
      site_takeover_title,
      site_takeover_subtitle,
      description,
      send_activation_mail = false,
      redirection_url,
      deliver_developer_broker_leads = true,
      allow_zoomin = true,
    } = {},
    bannerImage1 = {},
    frontImage1 = {},
    frontImage2 = {},
    frontImage3 = {},
    frontImage4 = {},
    logoImage1 = {},
    logoImage2 = {},
    bannerImage2 = {},
    bannerImage3 = {},
    bannerImage4 = {},
  } = data;

  if (update) {
    return {
      products: {
        74: {
          slot_id: id,
          promotion_type,
          entity_type_id,
          product_type,
          polygon_uuids,
          entity_id,
          builder_project_ids,
          amount,
          supply_user_uuid,
          opportunity_id,
          product_activation_id,
          start_date: getFormattedDate(start_date),
          end_date: getFormattedDate(end_date),
          organic_hard_target,
          organic_soft_target,
          inner_churn_radius,
          outer_churn_radius,
          profile_type: profileType,
          offer_text,
          property_video_url,
          site_takeover_title,
          site_takeover_subtitle,
          description,
          send_activation_mail,
          deliver_developer_broker_leads,
          notes,
          redirection_url,
          allow_zoomin,
          slot_product_cards: getImageCardDataForEdit(id, {
            bannerImage1,
            frontImage1,
            frontImage2,
            frontImage3,
            frontImage4,
            logoImage1,
            logoImage2,
            bannerImage2,
            bannerImage3,
            bannerImage4,
          }),
        },
      },
    };
  }
  return {
    products: {
      74: {
        entity_type_id,
        product_type,
        polygon_uuids,
        amount,
        supply_user_uuid,
        entity_id,
        builder_project_ids,
        opportunity_id,
        product_activation_id,
        start_date: getFormattedDate(start_date),
        end_date: getFormattedDate(end_date),
        organic_hard_target,
        organic_soft_target,
        inner_churn_radius,
        outer_churn_radius,
        profile_type: profileType,
        offer_text,
        product_details: {
          property_video_url,
          site_takeover_title,
          redirection_url,
          site_takeover_subtitle,
          allow_zoomin,
        },
        description,
        send_activation_mail,
        notes,
        deliver_developer_broker_leads,
        slot_product_cards: [
          {
            content: "Desktop Image 1",
            priority: 1,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Mobile Image 1",
            priority: 2,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Mobile Image 2",
            priority: 3,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Mobile Image 3",
            priority: 4,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Mobile Image 4",
            priority: 5,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Desktop Image 2",
            priority: 6,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Desktop Image 3",
            priority: 7,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Desktop Image 4",
            priority: 8,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Desktop Logo Image 1",
            priority: 9,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
          {
            content: "Mobile Logo Image 1",
            priority: 10,
            product_id: "74",
            slot_id: null,
            status: "active",
            sub_title: "",
            title: "",
          },
        ],
      },
    },
  };
};

export const getFormattedDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const getBuilderProjectIds = (idString = "") => {
  let arr = idString.split(",");
  return arr.map(Number);
};

const getImagesData = (priority, cardsData) => {
  let imageData = { image: {} };
  for (let card of cardsData) {
    if (card.priority === priority) {
      if ([1, 6, 7, 8].includes(priority)) {
        imageData.image.absolute_url = card.card_images_info.banner_image_url;
      } else if (priority === 9 || priority === 10) {
        imageData.image.absolute_url = card.card_images_info.logo_image_url;
      } else {
        imageData.image.absolute_url = card.card_images_info.cover_image_url;
      }
      imageData = { ...card, ...imageData };
      break;
    }
  }
  return imageData;
};

const mapProductType = (id) => {
  let type = "";
  const typeMap = Constants.productTypeByIdMap;
  for (let key in typeMap) {
    if (typeMap[key] === id) {
      type = key;
      break;
    }
  }
  return type;
};

const mapProductIdByType = (type) => {
  const typeMap = Constants.productTypeByIdMap;
  return typeMap[type];
};

export const mapCityListData = (data) => {
  let { other_cities = [], top_cities = [] } = data;
  let otherCities = other_cities.map((city) => {
    return { name: city.name, uuid: city.polygon_uuid };
  });
  let topCities = top_cities.map((city) => {
    return { name: city.name, uuid: city.polygon_uuid };
  });
  return [...otherCities, ...topCities];
};

export const mapSellerListData = (data) => {
  const { sellers_info = [] } = data;
  const sellers = sellers_info.map((seller) => {
    return { name: seller.name, uuid: seller.profile_uuid };
  });
  return sellers;
};
//main page single
export const siteTakeoverDedicatedApiParser = (data) => {
  const {
    id,
    product_ids,
    locality_details = [],
    product_type_id,
    builder_project_ids = [],
    opportunity_id,
    product_activation_id,
    amount,
    start_datetime,
    end_datetime,
    organic_hard_target,
    organic_soft_target,
    inner_churn_radius,
    outer_churn_radius,
    service,
    profileType,
    sellingType,
    notes,
    offer_text,
    slot_product_mappings: {
      74: {
        property_video_url,
        site_takeover_title,
        site_takeover_subtitle,
        redirection_url,
        allow_zoomin,
      } = {},
    } = {},
    description,
    send_activation_mail,
    deliver_developer_broker_leads,
    slot_product_cards,
    promoted_entity,
    seller_name,
  } = data;

  const parsedData = {
    slotDetails: {
      id,
      entity_type_id: promoted_entity["promoted_entity_type_id"] || 3,
      promotion_type: product_ids,
      product_type: mapProductType(product_type_id),
      polygon_uuids: locality_details[0]["polygon_uuid"],
      polygon_name: locality_details[0]["polygon_name"],
      seller_name,
      amount,
      supply_user_uuid: promoted_entity["supply_user_uuid"],
      entity_id: promoted_entity["entity_id"],
      builder_project_ids: builder_project_ids.join(","),
      opportunity_id,
      product_activation_id,
      start_date: new Date(start_datetime),
      end_date: new Date(end_datetime),
      duration: updateDuration(start_datetime, end_datetime),
      organic_hard_target: organic_hard_target,
      organic_soft_target: organic_soft_target,
      inner_churn_radius,
      outer_churn_radius,
      service,
      profileType,
      sellingType,
      notes,
      offer_text,
      property_video_url,
      site_takeover_title,
      site_takeover_subtitle,
      description,
      send_activation_mail,
      deliver_developer_broker_leads,
      redirection_url,
      allow_zoomin,
    },
    priorityArray: slot_product_cards
      .map((element) => element.priority)
      .filter((v, i, a) => a.indexOf(v) === i),
    bannerImage1: getImagesData(1, slot_product_cards),
    bannerImage2: getImagesData(6, slot_product_cards),
    bannerImage3: getImagesData(7, slot_product_cards),
    bannerImage4: getImagesData(8, slot_product_cards),
    frontImage1: getImagesData(2, slot_product_cards),
    frontImage2: getImagesData(3, slot_product_cards),
    frontImage3: getImagesData(4, slot_product_cards),
    frontImage4: getImagesData(5, slot_product_cards),
    logoImage1: getImagesData(9, slot_product_cards),
    logoImage2: getImagesData(10, slot_product_cards),
  };
  return parsedData;
};
//update
export const getEditSlotData = (data) => {
  const { products: { 74: productData } = {} } = data;
  const {
    entity_type_id,
    slot_id,
    polygon_uuids,
    entity_id,
    supply_user_uuid,
    amount,
    builder_project_ids,
    opportunity_id,
    product_activation_id,
    start_date,
    end_date,
    organic_hard_target,
    organic_soft_target,
    inner_churn_radius,
    outer_churn_radius,
    notes,
    offer_text,
    property_video_url,
    site_takeover_title,
    site_takeover_subtitle,
    description,
    send_activation_mail,
    deliver_developer_broker_leads,
    slot_product_cards,
    product_type,
    redirection_url,
    allow_zoomin,
  } = productData;
  return {
    slot_id: slot_id,
    package_details: {
      end_date: end_date,
      entity_type_id: entity_type_id.toString(),
      inner_churn_radius: inner_churn_radius,
      notes: notes,
      opportunity_id: opportunity_id,
      organic_hard_target: organic_hard_target,
      organic_soft_target: organic_soft_target,
      outer_churn_radius: outer_churn_radius,
      product_activation_id: product_activation_id,
      product_type_id: mapProductIdByType(product_type),
      promoted_listings: "",
      send_activation_mail: send_activation_mail,
      deliver_developer_broker_leads,
      start_date: start_date,
      supply_user_uuid: entity_type_id === 3 ? entity_id : supply_user_uuid,
      ...(entity_type_id === 3
        ? { builder_promoted_project_ids: getBuilderProjectIds(builder_project_ids) }
        : {}),
      ...(entity_type_id === 2 ? { amount, entity_id } : {}),
    },
    promoted_products: {
      74: {
        polygon_id: polygon_uuids,
        product_details: {
          ...(entity_type_id === 3
            ? { builder_project_ids: getBuilderProjectIds(builder_project_ids) }
            : {}),
          description: description,
          offer_text: offer_text,
          property_video_url,
          site_takeover_title,
          site_takeover_subtitle,
          redirection_url,
          allow_zoomin,
        },
        slot_product_cards: slot_product_cards,
      },
    },
  };
};

export const disablePreviousDates = (date) => {
  var today = new Date();
  today.setHours(0, 0, 0, 0);
  let calendarDate = new Date(date);
  calendarDate.setHours(0, 0, 0, 0);
  if (today > calendarDate) {
    return true;
  }
};
