import {
  PAYMENT_DETAILS,
  TERMSHEET_DETAILS,
  TERMSHEET_CREATED,
  TERMSHEET_CREATION_FAILED,
  RESET_TERMSHEET_CREATION,
  TERMSHEET_UPDATED,
  TERMSHEET_UPDATION_FAILED,
  MILESTONE_DETAILS,
  LEGAL_ENTITY_DETAILS,
  MILESTONE_DETAIL_FOR_PAYMENT,
  PACKAGE_ATTRIBUTES,
  TERMSHEET_PDF_URL,
  MILESTONE_PDF_URL,
  PACKAGE_SLOT_DETAILS,
  TERMSHEET_SEND_BACK_TO_RCM,
  RESET_PAYMENT,
  RESET_PRODUCT_ACTIVATION,
  PRODUCT_ACTIVATION_DONE,
  FETCH_TRANSACTION_HISTORY,
  SENT_FOR_REFUND_FLOW,
  LATE_CHEQUE_APPROVAL_HISTORY,
  REFUNDS_REVIEWERS_LIST,
  SENT_PAYMENT_LINK,
  CANCELED_INVOICE,
  SHARE_TERMSHEET_WITH_SELLER,
  VIEW_TERMSHEET,
  SENT_DEVELOPER_PAYMENT_LINK,
  SENT_PERFOMA_LINK,
  BROKER_PRODUCT_ACTIVATION,
  BROKER_PRODUCT_DETAILS_MAP,
  SEND_TERMSHEET_RCM,
  SHARE_TAX_INVOICE_CLIENT,
  UPDATE_ACTIVATION_PARAMS_LISTING_ID,
  RCM_CASE_FIXED,
  RCM_CASE_FIX_FAILED,
  GET_AUTO_ACTIVATION_LIST,
} from "../actions/termsheet.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAYMENT_DETAILS:
      return {
        ...state,
        payment: action.payload,
      };
    case MILESTONE_DETAILS:
      return {
        ...state,
        milestone: action.payload,
      };
    case LEGAL_ENTITY_DETAILS:
      return {
        ...state,
        accountLegalEntity: action.payload,
      };
    case MILESTONE_DETAIL_FOR_PAYMENT:
      return {
        ...state,
        paymentById: null,
        paymentByMilestoneId: action.payload,
      };
    case PACKAGE_ATTRIBUTES:
      return {
        ...state,
        packageAttributes: action.packageAttributes,
      };
    case TERMSHEET_PDF_URL:
      return {
        ...state,
        termsheetPDFUrl: action.termsheetPDFUrl,
      };
    case MILESTONE_PDF_URL:
      return {
        ...state,
        milestonePDFUrl: action.milestonePDFUrl,
      };
    case PACKAGE_SLOT_DETAILS:
      return {
        ...state,
        housingSlotDetails: {
          ...state.housingSlotDetails,
          ...action.housingSlotDetails,
        },
        showSlotDetails: action.showSlotDetails,
      };
    case RESET_PAYMENT:
      return {
        ...state,
        payment: null,
        paymentById: null,
      };
    case RESET_PRODUCT_ACTIVATION:
      return {
        ...state,
        productActivationStatus: {
          processed: false,
        },
      };
    case PRODUCT_ACTIVATION_DONE:
      return {
        ...state,
        productActivationStatus: action.payload,
        snackbar: {
          state: true,
          message: action.payload.processed
            ? "Product Activated Successfully!"
            : action.payload.errMsg,
          type: action.payload.processed ? "success" : "error",
        },
      };
    case GET_AUTO_ACTIVATION_LIST:
      return {
        ...state,
        developerAutoActivationProductIds: action.payload,
      };
    case FETCH_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: action.payload,
      };
    case SENT_FOR_REFUND_FLOW:
      return {
        ...state,
        sentForRefund: action.payload,
      };
    case LATE_CHEQUE_APPROVAL_HISTORY: {
      return {
        ...state,
        lateChequeApprovalHistory: action.lateChequeApprovalHistory,
      };
    }
    case REFUNDS_REVIEWERS_LIST:
      return {
        ...state,
        refundsReviewerList: action.refundsReviewerList,
      };
    case SENT_PAYMENT_LINK:
      return {
        ...state,
        sentPaymentLink: action.payload,
        snackbar: {
          state: action.payload.status,
          message: "Link Sent Successfully",
          type: "success",
        },
      };
    case CANCELED_INVOICE:
      return {
        ...state,
        snackbar: {
          state: true,
          message: action.payload,
          type: "info",
        },
      };
    case SHARE_TERMSHEET_WITH_SELLER:
      return {
        ...state,
        termsheetShareStatus: action.status,
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Termsheet shared Successfully",
        },
      };
    case VIEW_TERMSHEET:
      return {
        ...state,
        termsheetViewData: action.payload,
      };
    case SENT_DEVELOPER_PAYMENT_LINK:
      return {
        ...state,
        sentPaymentLink: action.payload,
        snackbar: {
          state: action.payload.status,
          message: "Payment Link Sent Successfully",
          type: "success",
        },
      };
    case SENT_PERFOMA_LINK:
      return {
        ...state,
        sentPerfomaLink: action.payload,
        snackbar: {
          state: action.payload.status,
          message: "Porforma Link Sent Successfully",
          type: "success",
        },
      };
    case BROKER_PRODUCT_ACTIVATION:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Activation request sent",
          type: "success",
        },
      };
    case BROKER_PRODUCT_DETAILS_MAP:
      return {
        ...state,
        productDetailsMap: action.payload,
      };
    case TERMSHEET_DETAILS:
      return {
        ...state,
        termsheetPDFUrl: null,
        termsheet: action.payload,
      };
    case TERMSHEET_CREATED:
      return {
        ...state,
        newTermsheet: action.payload.data,
        termsheet: action.payload,
        snackbar: {
          state: true,
          message: "New Termsheet Created",
          type: "success",
        },
      };
    case TERMSHEET_UPDATED:
      return {
        ...state,
        newTermsheet: action.payload.data,
        termsheet: action.payload,
        snackbar: {
          state: true,
          message: "Termsheet Updated!",
          type: "success",
        },
      };
    case RCM_CASE_FIXED:
      return {
        ...state,
        fixCaseStatus: action.fixCaseStatus,
        snackbar: {
          state: true,
          message:
            action.fixCaseStatus > 0
              ? "RCM Case Fixed Successfully!"
              : "Case didn't Proceed",
          type: "success",
        },
      };
    case TERMSHEET_SEND_BACK_TO_RCM:
      return {
        ...state,
        termsheetSendBackToRAM: action.payload,
        termsheet: {
          ...state.termsheet,
          data: {
            ...state.termsheet.data,
            ...action.payload.data,
          },
        },
      };
    case TERMSHEET_CREATION_FAILED:
      return {
        ...state,
        newTermsheet: {
          creationFailed: true,
        },
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Unable to create a new Termsheet!",
        },
      };
    case TERMSHEET_UPDATION_FAILED:
      return {
        ...state,
        newTermsheet: {
          creationFailed: true,
        },
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Unable to update the Termsheet!",
        },
      };
    case RCM_CASE_FIX_FAILED:
      return {
        ...state,
        newTermsheet: {
          caseFixFailed: true,
        },
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Unable to fix the RCM Case!",
        },
      };
    case RESET_TERMSHEET_CREATION:
      return {
        ...state,
        newTermsheet: null,
      };
    case SEND_TERMSHEET_RCM:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Termsheet sent to RCM",
          type: "success",
        },
      };
    case SHARE_TAX_INVOICE_CLIENT:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Tax Invoice Shared successfully!",
          type: "success",
        },
      };
    case UPDATE_ACTIVATION_PARAMS_LISTING_ID:
      return {
        ...state,
        termsheet: {
          ...state.termsheet,
          data: {
            ...state.termsheet.data,
            ...action.payload.data,
          },
        },
      };
  }
};

export default reducer;
