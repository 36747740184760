import { instance } from "../../lib/api";
import { parseImagesData } from "../../utils/FlatPanelUtils";
import Constants from "../../utils/CommonConstants";
import getCredentialsMap from "../../utils/getCredentialsMap";

export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_REJECTION_REASONS = "SET_REJECTION_REASONS";
export const SET_IMAGES_DATA = "SET_IMAGES_DATA";

export const resetFormData = () => (dispatch) => dispatch({ type: RESET_FORM_DATA });

export const setFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_FORM_DATA,
    payload: { section, data, index },
  });

export const fetchRejectionReasons = () => (dispatch) => {
  const url = `${process.env.DOMAINS.IMAGES}api/v1/files/reject-reasons`;
  dispatch({
    type: SET_REJECTION_REASONS,
    payload: {
      pending: true,
      success: null,
      error: null,
      data: null,
    },
  });
  return instance({
    url,
    method: "GET",
  })
    .then(({ data = [] }) => {
      dispatch({
        type: SET_REJECTION_REASONS,
        payload: {
          pending: false,
          success: true,
          error: false,
          data,
        },
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      dispatch({
        type: SET_REJECTION_REASONS,
        payload: {
          pending: false,
          success: false,
          error: true,
          data: null,
        },
      });
    });
};

export const fetchImagesData =
  ({ flatIds, category, housingShorts } = {}, page, perPageCount) =>
  (dispatch) => {
    const url = `${process.env.DOMAINS.IMAGES}api/v1/files/flat-files`;
    const _flatIds = flatIds ? flatIds.split(",").map((val) => val.trim()) : [];
    const apiData = {
      flat_ids: _flatIds,
      page,
      per_page: perPageCount,
      attr_id: getCredentialsMap(category, housingShorts).attr_id,
    };
    return instance({
      url,
      withCredentials: true,
      method: "POST",
      data: apiData,
    })
      .then(({ data = {} }) => {
        if (data.total > 0) {
          let imagesData = parseImagesData(data.images, _flatIds);
          return { images: imagesData, total: data.total, page: data.page };
        } else {
          return { images: [], total: data.total, page: data.page };
        }
      })
      .catch((error) => {
        const errMsg = Constants.getErrorMessage(error);
        dispatch({
          type: Constants.API_FAIL,
          errMsg: errMsg,
        });
      });
  };

export const submitImages = (data) => (dispatch) => {
  const url = `${process.env.DOMAINS.IMAGES}api/v1/files/feedback`;
  const apiData = {
    files: data || {},
  };
  return instance({
    url,
    method: "PATCH",
    data: apiData,
    withCredentials: true,
  })
    .then((response) => {
      let { data: { data: { message: msg } = {} } = {} } = response;
      dispatch({
        type: Constants.SNACKBAR_NOTIFICATION,
        msg: msg,
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

const moveElementToFront = (arr, element, fn) => {
  return [element, ...arr.filter(fn)];
};

export const editImages =
  ({ data, category, listingId, tag, flatIds, page, perPageCount, uploadFlow }) =>
  (dispatch) => {
    const { name: imageName } = data;
    const _flatIds = flatIds ? flatIds.split(",").map((val) => val.trim()) : [];
    data["upload_flow"] = uploadFlow;
    return postImage(data, listingId, tag)
      .then(() => {
        const newflatsIds = moveElementToFront(
          _flatIds,
          listingId,
          (item) => item !== listingId
        );
        return dispatch(
          fetchImagesData(
            { flatIds: newflatsIds.join(","), category },
            page,
            perPageCount
          )
        ).then((data) => {
          const { images = [] } = data || {};
          const editedImageIndex = images.findIndex(
            (image) => image.absolute_url.indexOf(imageName) > -1
          );
          if (editedImageIndex > -1) {
            return {
              ...data,
              images: moveElementToFront(
                images,
                images[editedImageIndex],
                (image) => image.absolute_url.indexOf(imageName) === -1
              ),
            };
          }
          return data;
        });
      })
      .catch((error) => {
        const errMsg = Constants.getErrorMessage(error);
        dispatch({
          type: Constants.API_FAIL,
          errMsg: errMsg,
        });
      });
  };

async function postImage(data, entityId, tag) {
  const { attr_id, extension, md5, name, upload_flow: uploadFlow } = data;
  const url = `${process.env.DOMAINS.IMAGES}api/v1/create_multiple_files_self_upload`;
  const apiData = {
    files: [
      {
        attr_id,
        extension,
        is_private: false,
        md5,
        name,
        obj_id: entityId,
        status: "new",
        tag: tag,
      },
    ],
    re_upload: "true",
    upload_source: "web",
    upload_flow: uploadFlow,
  };
  const response = await instance({
    url,
    withCredentials: true,
    method: "POST",
    data: apiData,
  });
  return response;
}
